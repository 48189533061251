@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.vidSnapBG {
  color: '#30A6DF !important';
}

.createScript {
  display: block;
  text-align: start;
  opacity: 1;
  background: rgb(48, 166, 223);
  padding: 14px;
  margin-right: 0px;
  font-weight: 600;
  color: white;
}

.createNewHeader {
  right: 30px;
  position: relative;
  /* top: 3px; */
}

.videoLength > li > label {
  width: 120px !important;
}

.container {
  margin: auto;
  padding: 20px;
  text-align: center;
}

.dropzone {
  border: 2px dashed #cccccc;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.dropzone.active {
  background-color: #91bbf9;
}

ul {
  list-style-type: none;
  padding: 0;
}

.custombutton {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #53389E;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.custombutton:hover {
  background-color: #6941C6;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh; /* Full screen height */
}

