.app {
    display: flex;
  }
  
  .content {
    flex: 1;
    padding: 10px;
    /* height: 100vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* overflow-y: auto; */
  }